Lyte.Mixin.register("crux-create-base-mixin", {
	/**
	 * callbacks -
	 * 	onFormCancel
	 * 	onCustomButtonClick
	 * 	onCustomButtonActionTrigger
	 * 	onCustomButtonBeforeShow
	 * 	onFormValueChange
	 * 	onInstanceObjKeyCreation
	 * 	onFormBeforeRender
	 * 	onFormAfterRender
	 * 	onSubformValueChange
	 * 	onVrFunctionResponse
	 * 	fetchLookupModuleData
	 * 	fetchLookupRecords
	 * 	onFormSave
	 * 	onFormAfterSave
	 * 	formFieldOfLookup
	 */
	actions: {
		openQuickCreateForm: function (fromModal, fromSubform, cxPropFieldData, cruxLookupElmId, subSectionCurntInstObj, recordObj) {
			let qcObject = { fromModal, fromSubform, cxPropFieldData, cruxLookupElmId, subSectionCurntInstObj, recordObj };
			qcObject.currentFieldNode = this.$node;
			let fieldMeta = cxPropFieldData || this.data.cxPropFieldData;
			qcObject.cxPropFieldData = fieldMeta;
			var onBeforeSaveCallBackResponse = this.invokeCruxFormCallBacks({ callbackEventName: 'onQuickCreateClick', onQuickCreateClick: qcObject });//no i18n
			onBeforeSaveCallBackResponse.then((promiseResponse) => {
				if (promiseResponse === 'NO_MATCHING_CALLBACKS') {
					setTimeout(() => {
						this.quickCreateFromModal = fromModal;
						let subformDetailsObj = { fromSubform, cxPropFieldData, cruxLookupElmId, subSectionCurntInstObj, recordObj };
						this.hideLookupDropdown(Object.assign({ fromModal }, subformDetailsObj));
						let layoutComponentData = this.data.cxPropLayoutComponentData, newLayoutRenderingObj = {},
							lookupModuleDetails = Lyte.deepCopyObject(fieldMeta.lookup.module || {}),
							originalLayoutComponentData = (layoutComponentData.layoutComponentDomNode.component.data.originalLayoutComponentData || {}),
							{ cxPropOutletValue } = originalLayoutComponentData;
						let lookupModuleRecord = layoutComponentData.cxInternalUtilityObj.lookupModuleMetaInfo[lookupModuleDetails.id];
						if (lookupModuleRecord) {
							lookupModuleDetails.moduleName = lookupModuleRecord.module_name;
						}
						newLayoutRenderingObj.cxPropModuleName = lookupModuleDetails.moduleName;
						newLayoutRenderingObj.cxPropModuleId = lookupModuleDetails.id;
						newLayoutRenderingObj.cxPropModuleApiname = lookupModuleDetails.api_name;
						newLayoutRenderingObj.cxPropOutletValue = (cxPropOutletValue || "body");//no i18n
						newLayoutRenderingObj.isQuickCreate = true;
						let callbackObject = {};
						callbackObject.onFormAfterSave = this.onFormAfterSave.bind(this, newLayoutRenderingObj, subformDetailsObj);
						callbackObject.onFormCancel = this.onFormCancel.bind(this, newLayoutRenderingObj, subformDetailsObj);
						newLayoutRenderingObj.cruxFormCallbacks = callbackObject;
						Lyte.Component.render('crux-createform', {//no i18n
							cxPropLayoutComponentData: newLayoutRenderingObj,
							cxPropRenderMode: "modal",//no i18n
						}, cxPropOutletValue);
						if (fromModal) {
							this.hideLookupModal(Object.assign({ fromModal }, subformDetailsObj));
						}
					}, 25);
				}
			});
		}
	},
	methods: {
		// Common methods across components
		dropdownBeforeShow: function () {
			//dropdownBeforeShow - crux
		},
		cruxOpenDropdown: function () {
			//cruxOpenDropdown - crux
		},
		commonDropDownOnHide: function () {
			//commonDropDownOnHide - crux
		},
		setdropdownData: function () {
			//setdropdownData - crux
		},
		fetchModuleData: function (id) {
			return this.invokeCruxFormCallBacks({ callbackEventName: 'fetchLookupModuleData', fetchLookupModuleData: { id } });//no i18n
		},
		cxfetchLookupRecords: function (moduleId, queryParams) {
			return this.invokeCruxFormCallBacks({ callbackEventName: 'fetchLookupRecords', fetchLookupRecords: { moduleId, queryParams } });//no i18n
		}
	},
	generateDynamicInstanceKey: function () {
		//Generate and create dynamic current instance object if not available
		var currentInstObjKey = this.data.currentInstObjKey;
		if (!currentInstObjKey) {
			currentInstObjKey = this.getDynamicCurrentInstanceObjKey({ actionName: "cxCreate" });//no i18n
			this.setData('currentInstObjKey', currentInstObjKey);//no i18n
		}
		if (currentInstObjKey) {
			currentInstObjKey = this.generateCurrentInstanceObject({ instanceObjKey: currentInstObjKey, moduleData: this.data.cxPropModuleData });
		}
		return currentInstObjKey;
	},
	getDynamicCurrentInstanceObjKey: function (customData) {
		if (!customData) {
			customData = {};
		}
		var actionName = customData.actionName || "", finalString = "cxInstObj" + actionName, len = customData.charLen || 3;//No I18n
		for (var k = 0; k < len; k++) { //eslint-disable-line @zoho/zstandard/proper-usage-of-loop
			finalString += Math.floor(Math.random() * 100).toString(36);
		}
		return finalString;
	},
	generateCurrentInstanceObject: function (customData) {
		if (!customData) {
			customData = {};
		}
		if (!customData.instanceObjKey && this.getDynamicCurrentInstanceObjKey) {
			customData.instanceObjKey = this.getDynamicCurrentInstanceObjKey(customData);
		}
		var currentInstObjKey = customData.instanceObjKey, copyFromExisting;
		if (customData.hasOwnProperty('copyFromExisting')) {
			copyFromExisting = true;
		}
		if (currentInstObjKey) {
			if (customData.moduleData && this.isEmptyObj(customData.moduleData[currentInstObjKey])) {
				customData.moduleData[currentInstObjKey] = {};
				if (copyFromExisting && customData.parentInstObjKey && customData.copyFromExisting.indexOf('moduleData') !== -1 && !this.isEmptyObj(customData.moduleData[customData.parentInstObjKey])) {
					customData.moduleData[currentInstObjKey] = Lyte.deepCopyObject(customData.moduleData[customData.parentInstObjKey]);
				}
			}
			if (customData.moduleSections) {
				var moduleSections = customData.moduleSections || [];
				moduleSections.forEach(function (section) {
					if (this.isEmptyObj(section[currentInstObjKey])) {
						section[currentInstObjKey] = {};
					}
				}.bind(this));
			}
			if (customData.moduleFields) {
				var moduleFields = customData.moduleFields || [];
				moduleFields.forEach(function (field) {
					if (this.isEmptyObj(field[currentInstObjKey])) {
						field[currentInstObjKey] = {};
					}
				}.bind(this));
			}
		}
		return customData.instanceObjKey;
	},
	deleteCurrentInstanceObject: function (customData) {
		if (!customData) {
			customData = {};
		}
		var currentInstObjKey = customData.instanceObjKey;
		if (currentInstObjKey) {
			if (customData.moduleData) {
				delete customData.moduleData[currentInstObjKey];
			}
			if (customData.moduleSections) {
				var moduleSections = customData.moduleSections || [];
				moduleSections.forEach(function (section) {
					delete section[currentInstObjKey];
					var sectionFields = section.fields || [];
					sectionFields.forEach(function (sfield) {
						delete sfield[currentInstObjKey];
					});
				});
			}
			if (customData.moduleFields) {
				var moduleFields = customData.moduleFields || [];
				moduleFields.forEach(function (field) {
					delete field[currentInstObjKey];
				});
			}
		}
	},
	isValidFieldToRender: function (fieldInfo, customData) {
		customData = customData || {};
		var columnsToSkip = customData.columnsToSkip || [], apiNamesToSkip = customData.apiNamesToSkip || [], uiTypeToSkip = customData.uiTypeToSkip || [];
		if (fieldInfo) {
			var isValid = fieldInfo.visible &&
				(
					columnsToSkip.indexOf(fieldInfo.column_name) === -1
					&& apiNamesToSkip.indexOf(fieldInfo.api_name) === -1 //no i18n
					&& uiTypeToSkip.indexOf(fieldInfo.ui_type) === -1
				);
			if (isValid && fieldInfo.column_name === "LAYOUTID" && customData.currentPage === "create") {
				isValid = false;
			}
			return isValid;
		}
		return false;
	},
	isValidVRFieldForInventory: function (fldMeta, moduleName) {
		let isInvModule = this.isInventoryModule(moduleName);
		if (isInvModule && ['DISCOUNT', 'TAX'].includes(fldMeta.column_name) &&//NO I18N
			!fldMeta.custom_field && validationUtils.isNotEmpty(fldMeta.subform)) {
			return true;
		}
		return false;
	},
	addCustomValidationsforField: function (customData) {
		if (!customData) {
			customData = {};
		}
		var fieldMeta = customData.cxPropFieldData || {}, formFieldList = customData.formFieldList && customData.formFieldList[fieldMeta.api_name];
		formFieldList.validation = [];
		if (fieldMeta.required) {
			formFieldList.validation.push('cxFormEmptyValueValidation');//no i18n
			if (fieldMeta.data_type === 'picklist') {
				formFieldList.validation.push('cxFormPicklistValidation');//no i18n
			}
		}
		if (fieldMeta.data_type === 'email') { //no i18n
			formFieldList.validation.push('cxFormEmailValidation');//no i18n
		} else if (fieldMeta.data_type === 'website') { //no i18n
			formFieldList.validation.push('cxFormWebsiteValidation');//no i18n
		} else if (fieldMeta.data_type === 'date' || fieldMeta.data_type === 'datetime') { //no i18n
			formFieldList.validation.push('datetimeValidation');//no i18n
		} else if (fieldMeta.data_type === "currency" || (fieldMeta.data_type === 'double' && fieldMeta.column_name !== 'EXCHANGERATE')) {
			formFieldList.validation.push('cxFormDecimalValidation');//no i18n
		} else if (fieldMeta.data_type === 'phone' || fieldMeta.column_name === 'FAX') {
			formFieldList.validation.push('cxFormPhonenumValidation');//no i18n
		} else if (fieldMeta.data_type === 'fileupload' || fieldMeta.data_type === 'imageupload') {
			formFieldList.validation.push('cxFormFileUploadValidation');//no i18n
		} else if ((fieldMeta.data_type === 'integer' || fieldMeta.data_type === 'bigint' && fieldMeta.column_name !== "PARTICIPANTID") && fieldMeta.column_name !== 'LAYOUTID' && fieldMeta.column_name !== "WIZARDID") {
			formFieldList.validation.push('cxFormIntegerValidation');//no i18n
		} else if (fieldMeta.column_name === 'TWITTER') { //no i18n
			formFieldList.validation.push('cxFormTwitteridValidation');//no i18n
		}
	},
	validateFieldValues: function () {
		var layoutComponentData = this.data.cxPropLayoutComponentData, cxUtilityObj = layoutComponentData.cxInternalUtilityObj, formFieldList = cxUtilityObj.formFieldList;
		var isErrorPresent = false, cxPropFormData = layoutComponentData.cxPropFormData;
		if (cxUtilityObj.subFormFieldApiVsMetaObject && Object.keys(cxUtilityObj.subFormFieldApiVsMetaObject).length) {
			isErrorPresent = this.validateCruxSubformData();
		}
		var parentErrObj = this.validateRecordForError({ formFieldList: formFieldList, cxPropFormData });
		cxPropFormData.$RECORD__Error__Object = parentErrObj;
		if (parentErrObj && Object.keys(parentErrObj).length) {
			isErrorPresent = true;
		}
		return isErrorPresent;
	},
	getCruxSubFormData: function (customData) {
		customData = customData || {};
		if (!customData.hasOwnProperty('getAllSubformData')) {
			customData.getAllSubformData = true;
		}
		let layoutComponentData = this.data.cxPropLayoutComponentData, cxUtilityObj = layoutComponentData.cxInternalUtilityObj, subformApiNames = {}, cxPropFormData = this.data.cxPropFormData;
		if (customData.getAllSubformData) {
			subformApiNames = cxUtilityObj.subFormFieldApiVsMetaObject;
		} else if (customData.subformApiName) {
			subformApiNames[customData.subformApiName] = cxUtilityObj.subFormFieldApiVsMetaObject[customData.subformApiName];
		}
		subformApiNames = subformApiNames || {};
		let returnObj = {};
		for (var subformKey in subformApiNames) {
			returnObj[subformKey] = cxPropFormData[subformKey];
		}
		return returnObj;
	},
	validateCruxSubformData: function (customData) {
		customData = customData || {};
		if (!customData.hasOwnProperty('validateAllSubforms')) {
			customData.validateAllSubforms = true;
		}
		if (!customData.hasOwnProperty('getAllSubformData')) {
			customData.getAllSubformData = true;
		}
		let layoutComponentData = this.data.cxPropLayoutComponentData, subformApiNames = {};
		if (customData.getAllSubformData) {
			subformApiNames = layoutComponentData.cxInternalUtilityObj.subFormFieldApiVsMetaObject;
		} else if (customData.subformApiName) {
			subformApiNames[customData.subformApiName] = layoutComponentData.cxInternalUtilityObj.subFormFieldApiVsMetaObject[customData.subformApiName];
		}
		subformApiNames = subformApiNames || {};
		let isErrorPresent = false;
		for (var subformKey in subformApiNames) {
			let subformNodeData = this.getSubformNode(subformKey);
			if (subformNodeData.cruxSubformNode) {
				let val = subformNodeData.cruxSubformNode.component.validate();
				if (!val && isErrorPresent === false) {
					isErrorPresent = true;
				}
			}
		}
		return isErrorPresent;
	},
	getSubformNode: function (subformApiName) {
		let layoutComponentData = this.data.cxPropLayoutComponentData,
			subformSelector = `.${layoutComponentData.layoutComponentDomNode.getContentWrapperClass()} #cxCreateSubform_${subformApiName}`,
			cruxSubformNodeSelector = `${subformSelector} crux-subform`;
		return {
			cruxSubformNode: $L(cruxSubformNodeSelector)[0],
			cxCreateFormSubformSectionNode: $L(subformSelector)[0]
		};
	},
	validateRecordForError: function (customData) {
		var { cxPropFormData, formFieldList } = customData, errObj = {};
		for (var formField in formFieldList) {
			var fldMetaInfo = formFieldList[formField];
			if (fldMetaInfo.fieldDataType === "subform") {
				continue;
			}
			var lytePropertyToCheck = { mandatory: fldMetaInfo.mandatory, type: fldMetaInfo.lyteAtrrType, validation: fldMetaInfo.validation };
			for (var lyteProp in lytePropertyToCheck) {
				if (lyteProp !== "validation") {
					let fieldValue = cxPropFormData[formField];
					if (cxPropFormData && cxPropFormData.$ && cxPropFormData.$.error && cxPropFormData.$.error[formField]) {
						fieldValue = cxPropFormData.$.error[formField].value;
					}
					if (lyteProp === "type") {
						switch (fldMetaInfo.json_type) {
							case 'double':
							case 'integer':
								fieldValue = this.getReverseTypeConvertedValue(fldMetaInfo.json_type, fieldValue, cxPropFormData, formField);
								break;
							default:
								if (fldMetaInfo.fieldDataType === "multiselectpicklist") {
									fieldValue = this.getReverseTypeConvertedValue(fldMetaInfo.fieldDataType, fieldValue, cxPropFormData, formField);
								} else if (fldMetaInfo.fieldDataType === "multiuserlookup") {
									fieldValue = this.getReverseTypeConvertedValue(fldMetaInfo.fieldDataType, fieldValue, cxPropFormData, formField);
								}
								break;
						}
					}
					var response = Lyte.checkProperty(lyteProp, fieldValue, formField, lytePropertyToCheck[lyteProp]);
					if (response !== true && !errObj[formField]) {
						errObj[formField] = response;
					}
				} else if (!errObj[formField]) {
					var fldValidation = lytePropertyToCheck[lyteProp] || [], validationLen = fldValidation.length;
					for (var k = 0; k < validationLen; k++) {
						var validatorName = fldValidation[k];
						if (this[validatorName]) {
							var validatorData = {}; validatorData.fieldProperties = fldMetaInfo;
							validatorData.fieldName = formField;
							validatorData.fieldValue = cxPropFormData[formField];
							var returnValue = this[validatorName](validatorData);
							if (returnValue !== true) {
								errObj[formField] = returnValue; break;
							}
						}
					}
				}
			}
		}
		return errObj;
	},
	getReverseTypeConvertedValue: function (fieldType, fieldValue, cxPropFormData, fieldApiname) {
		var returnValue = fieldValue;
		switch (fieldType) {
			case 'multiselectpicklist':
				try {
					returnValue = JSON.parse(fieldValue);
				} catch (exe) {
					if (Array.isArray(fieldValue)) {
						returnValue = fieldValue;
					} else if (typeof fieldValue === "string") {
						returnValue = fieldValue ? fieldValue.split('; ') : [];
					}
				}
				break;
			case 'multiuserlookup':
				returnValue = fieldValue;
				if (cxPropFormData.$ && cxPropFormData.$.model && cxPropFormData.$.model.fieldList[fieldApiname]) {
					let transformType = cxPropFormData.$.model.fieldList[fieldApiname].type;
					try {
						if (Lyte.Transform[transformType]) {
							returnValue = Lyte.Transform[transformType].serialize(fieldValue);
						}
					} catch (exception) {
						returnValue = fieldValue;
					}
				}
				break;
			case 'double':
			case 'integer':
				returnValue += '';
				break;
		}
		return returnValue;
	},
	/*
	setSubFormFieldErrorDetailsinDom: function (errorObject) {
		var layoutComponentData = this.data.cxPropLayoutComponentData, cxPropFormData = layoutComponentData.cxPropFormData;
		if (layoutComponentData.subFormFieldApiVsMetaObject && Object.keys(layoutComponentData.subFormFieldApiVsMetaObject).length) {
			for (var subformKey in layoutComponentData.subFormFieldApiVsMetaObject) {
				var currentSubformRecords = cxPropFormData[subformKey] || [];
				currentSubformRecords.forEach(function (eachSubformRec) {
					var currentFormErrorDetails = errorObject || eachSubformRec.$RECORD__Error__Object || {};
					if (Object.keys(currentFormErrorDetails).length) {
						Lyte.Component.set(eachSubformRec, '$Toggle__Error__Observer', !eachSubformRec.$Toggle__Error__Observer);//no i18n
					}
				}.bind(this));
			}
		}
	},
	*/
	setParentFieldErrorDetailsinDom: function (errorObject) {
		var layoutComponentData = this.data.cxPropLayoutComponentData, cxUtilityObj = layoutComponentData.cxInternalUtilityObj, cxPropFormData = layoutComponentData.cxPropFormData;
		if (errorObject && errorObject.readyState && errorObject.responseText) {
			errorObject = (errorObject.responseText ? JSON.parse(errorObject.responseText) : {}).data || {};
		}
		var currentInstObjKey = layoutComponentData.currentInstObjKey, currentFormErrorDetails = errorObject || cxPropFormData.$RECORD__Error__Object || {};
		var errorKeys = Object.keys(currentFormErrorDetails) || [];
		errorKeys.forEach(function (eachErrorField) {
			var entityField = cxUtilityObj.layoutFieldApiVsMetaObject[eachErrorField];
			entityField[currentInstObjKey].fieldErrorDetails = currentFormErrorDetails[entityField.api_name];
			if (entityField[currentInstObjKey].isSubFormAggField) {
				var currentError = entityField[currentInstObjKey].fieldErrorDetails;
				if (currentError && currentError.message) {
					var finalErrorMessage = currentError.message;
					if (!currentError.ignoreActualErrorMessage) {
						finalErrorMessage = this.getActualErrorMessage({ errorData: currentError, fieldMeta: entityField });
					}
					Lyte.Component.set(entityField[currentInstObjKey], 'errorMesage', finalErrorMessage);//no i18n
				} else {
					Lyte.Component.set(entityField[currentInstObjKey], 'errorMesage', "");//no i18n
				}
			} else {
				Lyte.Component.set(entityField[currentInstObjKey], 'observeErrorDetails', !entityField[currentInstObjKey].observeErrorDetails);//no i18n
			}
			$L.fastdom.measure(function (entityField) {
				var fieldsNode = this.getCxFieldsDomNode(entityField);
				if (!cxUtilityObj.hasOwnProperty('errorFocusDetails')) {
					cxUtilityObj.errorFocusDetails = {};
				}
				if (fieldsNode) {
					var currNodeOffset = fieldsNode.getBoundingClientRect();
					this.setErrorFocusField({ errFocusDetails: cxUtilityObj.errorFocusDetails, currOffsetTop: currNodeOffset.top, currOffsetLeft: currNodeOffset.left, fieldsNode: fieldsNode });
				}
			}.bind(this, entityField));
		}.bind(this));
	},
	focusFirstErrorFieldInDom: function () {
		$L.fastdom.measure(function () {
			var layoutComponentData = this.data.cxPropLayoutComponentData, errFocusDetails = layoutComponentData.cxInternalUtilityObj.errorFocusDetails;
			if (errFocusDetails && errFocusDetails.errorFieldToFocus) {
				var fldsComponent = errFocusDetails.errorFieldToFocus;
				var lyteInputNode = fldsComponent.querySelector('lyte-input');//no i18n
				if (lyteInputNode) {
					lyteInputNode.focus();
				}
				if (fldsComponent.scrollIntoView) {
					fldsComponent.scrollIntoView({ block: "center", behavior: 'smooth' });//no i18n
				}
			}
		}.bind(this));
	},
	setErrorFocusField: function (customData) {
		customData = customData || {};
		var errFocusDetails = customData.errFocusDetails;
		if (errFocusDetails.hasOwnProperty('prevOffsetTop')) {
			if (errFocusDetails.prevOffsetTop === 0 || errFocusDetails.prevOffsetTop > customData.currOffsetTop) {
				errFocusDetails.prevOfftop = customData.currOffsetTop; errFocusDetails.prevOffsetLeft = customData.currOffsetLeft;
				errFocusDetails.errorFieldToFocus = customData.fieldsNode;
			} else if (errFocusDetails.prevOffsetTop === customData.currOffsetTop && errFocusDetails.prevOffsetLeft > customData.currOffsetLeft) {
				errFocusDetails.prevOffsetTop = customData.currOffsetTop; errFocusDetails.prevOffsetLeft = customData.currOffsetLeft;
				errFocusDetails.errorFieldToFocus = customData.fieldsNode;
			}
		} else {
			errFocusDetails.prevOffsetTop = customData.currOffsetTop; errFocusDetails.prevOffsetLeft = customData.currOffsetLeft;
			errFocusDetails.errorFieldToFocus = customData.fieldsNode;
		}
	},
	getCxFieldsDomNode: function (entityField) {
		var layoutComponentData = this.data.cxPropLayoutComponentData, parentNode = document;
		if (layoutComponentData && layoutComponentData.layoutComponentDomNode) {
			parentNode = layoutComponentData.layoutComponentDomNode;
		}
		var selector = '.' + layoutComponentData.currentInstObjKey + 'Cx' + layoutComponentData.cxPropModuleName + entityField.column_name;
		return parentNode.querySelector(selector);
	},
	clearFieldErrorDetailsinDom: function () {
		var layoutComponentData = this.data.cxPropLayoutComponentData, cxUtilityObj = layoutComponentData.cxInternalUtilityObj, cxPropFormData = layoutComponentData.cxPropFormData;
		var currentInstObjKey = cxUtilityObj.currentInstObjKey, allFields = this.getCurrentLayoutValidFields(cxUtilityObj) || [];
		allFields.forEach(function (entityField) {
			entityField[currentInstObjKey].fieldErrorDetails = {};
			Lyte.Component.set(entityField[currentInstObjKey], 'observeErrorDetails', !entityField[currentInstObjKey].observeErrorDetails);//no i18n
		});
		if (cxUtilityObj.subFormFieldApiVsMetaObject && Object.keys(cxUtilityObj.subFormFieldApiVsMetaObject).length) {
			for (var subformKey in cxUtilityObj.subFormFieldApiVsMetaObject) {
				var currentSubformRecords = cxPropFormData[subformKey] || [];
				currentSubformRecords.forEach(function (eachSubformRec) {
					Object.defineProperty(eachSubformRec, '$RECORD__Error__Object', {
						enumerable: false,
						writable: true,
						value: {}
					});
					if (!eachSubformRec.hasOwnProperty('$Toggle__Error__Observer')) {
						Object.defineProperty(eachSubformRec, '$Toggle__Error__Observer', {
							enumerable: false,
							writable: true,
							value: undefined
						});
					}
					Lyte.Component.set(eachSubformRec, '$Toggle__Error__Observer', !eachSubformRec.$Toggle__Error__Observer);//no i18n
				}.bind(this));
			}
		}
	},
	validateAndSaveForm: function (customData) {
		customData = customData || {};
		if (!customData.hasOwnProperty('validateAndSave')) {
			customData.validateAndSave = true;
		}
		if (!customData.hasOwnProperty('validateCruxCreateForm')) {
			customData.validateCruxCreateForm = true;
		}
		if (!customData.hasOwnProperty('validateCruxSubform')) {
			customData.validateCruxSubform = true;
		}
		var layoutComponentData = this.data.cxPropLayoutComponentData, cxPropFormData = layoutComponentData.cxPropFormData;
		Object.defineProperty(cxPropFormData, '$RECORD__Error__Object', {
			enumerable: false,
			writable: true,
			value: {}
		});
		this.clearFieldErrorDetailsinDom();
		delete layoutComponentData.cxInternalUtilityObj.errorFocusDetails;
		var isErrorPresent = this.validateFieldValues();
		if (isErrorPresent) {
			this.setParentFieldErrorDetailsinDom();
			//this.setSubFormFieldErrorDetailsinDom();
			this.focusFirstErrorFieldInDom();
			return;
		}
		if (customData.validateAndSave) {
			this.processValidationRules({ dontFocus: false, callbackFunc: this.saveCreateForm.bind(this, customData.currentButtonObj) });
		}
	},
	saveCreateForm: function (currentButtonObj) {
		var layoutComponentData = this.data.cxPropLayoutComponentData;
		if (layoutComponentData.isValidationRulePassed) {
			return;
		}
		var onBeforeSaveCallBackResponse = this.invokeCruxFormCallBacks({ callbackEventName: 'onFormBeforeSave', onFormBeforeSave: { currentButtonObj } });//no i18n
		onBeforeSaveCallBackResponse.then(function (promiseResponse) {
			let skippedExecution = true;
			if (promiseResponse === false) {
				return skippedExecution;
			} this.afterBeforeSaveCallBackResponse(promiseResponse, currentButtonObj);
		}.bind(this));
		return false;
	},
	afterBeforeSaveCallBackResponse: function (beforeSavePromiseResponse, currentButtonObj) {
		var moduleData = (this.data.cxPropModuleData || this.data.cxPropLayoutComponentData.cxPropModuleData) || {};
		var onSaveCallBackResponse = this.invokeCruxFormCallBacks({ callbackEventName: 'onFormSave', onFormSave: { currentButtonObj } });//no i18n
		onSaveCallBackResponse.then(function (promiseResponse = {}) {
			if (promiseResponse.isSaveFailed) {
				this.handleFailureResponse(promiseResponse, currentButtonObj);
			} else {
				let returnObj = {};
				if (promiseResponse.hasOwnProperty('saveResponse')) {
					returnObj = promiseResponse;
				} else if (promiseResponse.hasOwnProperty(moduleData.id)) {
					returnObj.saveResponse = promiseResponse[moduleData.id];
				} else {
					returnObj.saveResponse = promiseResponse;
				}
				this.handleSuccessResponse(returnObj, currentButtonObj);
			}
		}.bind(this));
	},
	handleFailureResponse: function (promiseResponse) {
		var errResponse = promiseResponse && promiseResponse.errorObject, cxUtilityObj = this.data.cxPropLayoutComponentData.cxInternalUtilityObj;
		if (errResponse.onreadystatechange) {
			var data, response = errResponse.responseText ? JSON.parse(errResponse.responseText) : {};
			if (response.data) {
				for (var key in response) {
					data = response[key];
				}
				var dL = data ? data.length : 0;
				for (var i = 0; i < dL; i++) {
					var eObject = this.getFinalErrorObjectDetails(data[i], cxUtilityObj);
					eObject = this.getFinalErrorObjectDetails(eObject, cxUtilityObj);
					var fieldName = eObject.details.api_name, finalErrObj = {};
					finalErrObj[fieldName] = eObject;
					this.setParentFieldErrorDetailsinDom(finalErrObj);
				}
			} else {
				switch (response.code) {
					case 'NO_PERMISSION':
						//renderingUtils.displayPermissionDenied();
						break;
					case "INVALID_DATA":
						/*	
						if (response.message === "can't update the converted record") {

						}
						if (response.message === "the id given seems to be invalid") {

						}
						*/
						break;
				}
			}
		} else {
			let finalErrObj = errResponse || {};
			this.setParentFieldErrorDetailsinDom(finalErrObj);
		}
	},
	getFinalErrorObjectDetails: function (eObject, cxUtilityObj) {

		function parseValidApiname(fldList, pPath, pInd, pArr, pVal) {
			if (fldList[pVal] && pVal !== "id") {
				eObject.details.api_name = pVal;
				return;
			}
			var isvalidfldfound = false;
			while (!isvalidfldfound) {
				if (pPath.indexOf('[') !== -1) {
					pPath = pPath.split('[')[0];
				}
				if (fldList[pPath] && pPath !== "id") {
					isvalidfldfound = true;
					eObject.details.api_name = pPath;
				} else {
					pPath = pArr[pInd - 1];
					pInd -= 1;
				}
				if (pPath === undefined) {
					isvalidfldfound = true;
				}
			}
		}

		//added for jsonpath issue
		if (eObject && eObject.details && eObject.details.json_path) {
			var pathArr = eObject.details.json_path.split('.');
			var pathLen = pathArr.length, formFldList = cxUtilityObj.formFieldList, errApiname = eObject.details.api_name;;
			for (var k1 = 0; k1 < pathLen; k1++) {
				var pathval = pathArr[k1];
				if (pathval.indexOf('[') !== -1) {
					var parseAPI = pathval.split('[');
					if (parseAPI && parseAPI[0] && formFldList[parseAPI[0]] && formFldList[parseAPI[0]].type === "relation") {
						eObject.details.parent_api_name = parseAPI[0];
						eObject.details.index = Number(parseAPI[1].split(']')[0]);
					}
				}

				if (pathval === errApiname) {
					var prevInd = k1 - 1, prevPath = pathArr[prevInd];
					if (eObject.details.parent_api_name) {
						parseValidApiname(cxUtilityObj.subformFieldList[eObject.details.parent_api_name], prevPath, prevInd, pathArr, pathval);
					} else {
						parseValidApiname(formFldList, prevPath, prevInd, pathArr, pathval);
					}
				}
			}
			return eObject;
		}
		return eObject;
	},
	handleSuccessResponse: function (promiseResponse, currentButtonObj) {
		let respObj = { saveResponse: promiseResponse.saveResponse, currentButtonObj };
		if (respObj && respObj.saveResponse && respObj.saveResponse.hasOwnProperty('quickCreatedRecord')) {
			respObj.quickCreatedRecord = respObj.saveResponse.quickCreatedRecord;
		} else if (promiseResponse && promiseResponse.hasOwnProperty('quickCreatedRecord')) {
			respObj.quickCreatedRecord = promiseResponse.quickCreatedRecord;
		}
		var afterSaveCallBackResponse = this.invokeCruxFormCallBacks({ callbackEventName: 'onFormAfterSave', onFormAfterSave: respObj });//no i18n
		afterSaveCallBackResponse.then(function (afterPromiseResponse) {
			let skippedExecution = true;
			if (afterPromiseResponse === false) {
				return skippedExecution;
			}
		}.bind(this));
	},
	invokeCruxFormCallBacks: async function (customData) {
		customData = customData || {};
		let callbackEventName = customData.callbackEventName, layoutComponentData = this.data.cxPropLayoutComponentData, cruxFormCallbacks = layoutComponentData.cruxFormCallbacks || {},
			callBackObject = Object.assign({}, (customData[callbackEventName] || {})),
			layoutDomNode = layoutComponentData.layoutComponentDomNode;
		if (layoutDomNode && layoutDomNode.getFormData) {
			callBackObject.formData = layoutDomNode.getFormData();
		}
		let currentPage = layoutComponentData.cxInternalUtilityObj.currentPage;
		if (['onFormSave', 'onFormAfterSave', 'onFormBeforeSave'].includes(callbackEventName) && currentPage !== 'create' && layoutDomNode && layoutDomNode.getFormDirtyAttributes) {
			callBackObject.formDirtyAttributes = layoutDomNode.getFormDirtyAttributes();
		}
		return new Promise(async function (resolve) {
			let callBackMethod = cruxFormCallbacks[callbackEventName], isMethod = false, componentScope = layoutDomNode.component;
			if (componentScope.getMethods(callbackEventName)) {
				callBackMethod = componentScope.getMethods(callbackEventName);
				isMethod = true;
			}
			if (callBackMethod) {
				let callBackResponse;
				if (isMethod) {
					callBackResponse = componentScope.executeMethod(callbackEventName, callBackObject);
				} else {
					callBackResponse = cruxFormCallbacks[callbackEventName].call(this, callBackObject);
				}
				if (callBackResponse && callBackResponse.then) {
					callBackResponse.then(
						function (sucessReponse = {}) {
							resolve(sucessReponse);
						},
						function (failureReponse = {}) {
							resolve(failureReponse);
						}.bind(this));
				} else {
					resolve(callBackResponse = {});
				}
			} else if (layoutComponentData.isRequestLayerSupported) {
				switch (callbackEventName) {
					case "fetchLookupModuleData": {
						let moduleResp = await this.fetchLookupModuleData(callBackObject);
						resolve(moduleResp || {});
						break;
					}
					case "fetchLookupRecords": {
						let getRecordsResp = await this.fetchLookupRecordsData(callBackObject);
						resolve(getRecordsResp || {});
						break;
					}
					case "onFormSave": {
						callBackObject.moduleId = layoutComponentData.cxPropModuleId || (layoutComponentData.cxPropModuleData && layoutComponentData.cxPropModuleData.id);
						callBackObject.moduleName = layoutComponentData.cxPropModuleName || (layoutComponentData.cxPropModuleData && layoutComponentData.cxPropModuleData.module_name);
						callBackObject.currentPage = currentPage;
						let recordSaveResp = await this.saveCurrentForm(callBackObject, layoutComponentData.isQuickCreate, layoutComponentData);
						resolve(recordSaveResp || {});
						break;
					}
					case "formFieldOfLookup": {
						callBackObject.layoutComponentDomNode = layoutComponentData.layoutComponentDomNode;
						let recordSaveResp = await this.setFieldOfLookupData(callBackObject);
						resolve(recordSaveResp || {});
						break;
					}
					case 'onFormAfterSave': {
						this.setSaveResponseInMessage(callBackObject, layoutComponentData);
						break;
					}
					default:
						resolve("NO_MATCHING_CALLBACKS");
						break;
				}
			} else {
				resolve("NO_MATCHING_CALLBACKS");
			}
		}.bind(this));
	},
	invokeCruxModalPopverCallBacks: function (customData) {
		customData = customData || {};
		let callbackEventName = customData.callbackEventName, layoutComponentData = this.data.cxPropLayoutComponentData, cruxModalPopverCallBacks = {};
		if (customData.isPopover) {
			cruxModalPopverCallBacks = layoutComponentData.cruxPopverCallBacks || {};
		} else if (customData.isModal) {
			cruxModalPopverCallBacks = layoutComponentData.cruxModalCallBacks || {};
		}
		return new Promise(function (resolve) {
			if (cruxModalPopverCallBacks[callbackEventName]) {
				var callBackResponse = cruxModalPopverCallBacks[callbackEventName].apply(this, (customData.lyteUiCompArgs || []));
				if (callBackResponse && callBackResponse.then) {
					callBackResponse.then(
						function (sucessReponse) {
							resolve(sucessReponse);
						},
						function (failureReponse) {
							resolve(failureReponse);
						});
				} else {
					resolve(callBackResponse);
				}
			} else {
				resolve("NO_MATCHING_CALLBACKS");
			}
		}.bind(this));
	},
	getActualErrorMessage: function (customData) {
		customData = customData || {};
		var errorData = customData.errorData || {}, fieldMeta = customData.fieldMeta || {}, layoutComponentData = this.data.cxPropLayoutComponentData;
		var displayLabel = typeof $ESAPI !== "undefined" ? $ESAPI.encoder().encodeForHTML(fieldMeta.field_label) : fieldMeta.field_label;//No I18N
		var currentInstObjKey = layoutComponentData.currentInstObjKey, moduleData = layoutComponentData.cxPropModuleData || {}, duplicateErrDetails = {};
		switch (errorData.code) {
			case 'ERR02'://Empty Check
				return _cruxUtils.getI18n("crm.field.empty.check", displayLabel);//no i18n
			case 'ERR03'://Type mismatch Check
			case 'ERR08'://pattern mismatch Check
				return _cruxUtils.getI18n("crm.field.valid.check", displayLabel);//no i18n
			case 'ERR04'://exceeding decimal length Check
				return _cruxUtils.getI18n("crm.field.valid.decimal.check2", [displayLabel, errorData.allowedDecimal]);//no i18n
			case 'DUPLICATE_DATA':
				if (errorData.details && errorData.details.more_records && moduleData.generated_type === "default") {
					duplicateErrDetails.message = _cruxUtils.getI18n("crm.duplicate.value.not.allowed") + ' ' + _cruxUtils.getI18n('crm.duplicate.value.available.multiple', [moduleData.singular_label.toLowerCase(), displayLabel]);//no i18n
					duplicateErrDetails.more_records = true;
				} else {
					duplicateErrDetails.message = _cruxUtils.getI18n("crm.duplicate.value.not.allowed") + ' ' + _cruxUtils.getI18n("crm.duplicate.value.available", [moduleData.singular_label.toLowerCase(), displayLabel]);//no i18n
				}
				duplicateErrDetails.duplicateFdetails = {};
				duplicateErrDetails.duplicateFdetails.id = errorData.details && errorData.details.duplicate_record ? errorData.details.duplicate_record.id : undefined;
				duplicateErrDetails.duplicateFdetails.key = errorData.details.api_name;
				duplicateErrDetails.duplicateFdetails.dupFieldLabel = displayLabel;
				duplicateErrDetails.duplicateFdetails.dupColName = fieldMeta.column_name;
				duplicateErrDetails.duplicateFdetails.modLabel = moduleData.singular_label;
				duplicateErrDetails.duplicateFdetails.moduleN = moduleData.module_name;
				duplicateErrDetails.duplicateFdetails.modPluLabel = moduleData.plural_label;
				duplicateErrDetails.duplicateFdetails.detailsFromserver = errorData.details;
				Lyte.Component.set(fieldMeta[currentInstObjKey], 'duplicateErrorDetails', duplicateErrDetails);//no i18n
				Lyte.Component.set(fieldMeta[currentInstObjKey], 'isErrorYieldNeeded', true);//no i18n
				return duplicateErrDetails.message;
		}
		return errorData.message;
	},
	showHideLoadingDiv: function (show) {
		if (typeof commonUtils !== "undefined" && commonUtils.showHideLoadingDiv) {
			commonUtils.showHideLoadingDiv(show);
		}
	},
	setTabIndexForFields: function (customData) {
		customData.setDummyValue = true;
	},
	isInventoryModule: function (module) {
		return module === "Quotes" || module === "Invoices" || module === "SalesOrders" || module === "PurchaseOrders";//NO I18N
	},
	getApiVsFieldMetaMappingObject: function (customData) {
		var paramObject = {
			currencyFieldsArray: customData.currencyFieldsArray,
			jsonTypeConversionFields: customData.jsonTypeConversionFields,
			fieldDatatypeVsMetaObject: customData.fieldDatatypeVsMetaObject,
			fieldsArray: customData.fieldsArray,
			mapProperty: 'api_name'
		};
		if (customData.hasOwnProperty('fieldOfLookupDetails')) {
			paramObject.fieldOfLookupDetails = customData.fieldOfLookupDetails;
		}
		return this.getMetaMappingObject(paramObject);
	},
	getIdVsFieldMetaMappingObject: function (customData) {
		return this.getMetaMappingObject({ fieldsArray: customData.fieldsArray, mapProperty: 'id' });
	},
	getMetaMappingObject: function (customData) {
		var { fieldsArray,
			mapProperty,
			currencyFieldsArray,
			fieldOfLookupDetails,
			jsonTypeConversionFields,
			fieldDatatypeVsMetaObject } = customData, returnObj = {};
		fieldsArray = fieldsArray || [];
		fieldsArray.forEach(function (eachFld) {
			if (!returnObj.hasOwnProperty(eachFld[mapProperty])) {
				returnObj[eachFld[mapProperty]] = eachFld;
			}
			var isValidCurrencyField = eachFld.data_type === "currency" || (eachFld.data_type === "formula" && eachFld.formula && eachFld.formula.return_type === "currency");
			if (customData.hasOwnProperty('currencyFieldsArray') && isValidCurrencyField) {
				currencyFieldsArray.push(eachFld);
			}
			if (customData.hasOwnProperty('fieldOfLookupDetails') && !this.isEmptyObj(eachFld.association_details)) {
				var lookupField = eachFld.association_details.lookup_field, relatedField = eachFld.association_details.related_field;
				if (!fieldOfLookupDetails.hasOwnProperty(lookupField.api_name)) {
					fieldOfLookupDetails[lookupField.api_name] = [];
				}
				var folObj = { currentFormField: eachFld.api_name, lookupModuleMapField: relatedField };
				fieldOfLookupDetails[lookupField.api_name].push(folObj);
			}
			if (customData.hasOwnProperty('jsonTypeConversionFields') &&
				(["integer", "double"].indexOf(eachFld.json_type) !== -1 || ["multiselectpicklist", "multiuserlookup"].indexOf(eachFld.data_type) !== -1) &&
				jsonTypeConversionFields.indexOf(eachFld.api_name) === -1) {
				jsonTypeConversionFields.push(eachFld.api_name);
			}
			if (customData.hasOwnProperty('fieldDatatypeVsMetaObject')) {
				if (!fieldDatatypeVsMetaObject.hasOwnProperty(eachFld.data_type)) {
					fieldDatatypeVsMetaObject[eachFld.data_type] = [];
				}
				fieldDatatypeVsMetaObject[eachFld.data_type].push(eachFld);
			}
		}.bind(this));
		return returnObj;
	},
	processModuleFormulaFields: function (apiName) {
		let cxPropLayoutComponentData = this.data.cxPropLayoutComponentData, currentInstObjKey = cxPropLayoutComponentData.currentInstObjKey,
			cxUtilityObj = cxPropLayoutComponentData.cxInternalUtilityObj,
			subformsObj = cxUtilityObj.subformApinameVsSectionMeta || {};
		for (var subformApi in subformsObj) {
			let subformObj = subformsObj[subformApi], instObj = subformObj[currentInstObjKey] || {};
			if (instObj.evaluateModuleFormula) {
				//instObj.evaluateModuleFormula(apiName);
				apiName = `Modified API Name ${apiName}`;
			}
		}
	},
	getTypeCovertedFieldValue: function (formModelFieldObj, fieldApiname, fieldValue) {
		var finalValue = fieldValue, fieldModelProperties = formModelFieldObj[fieldApiname];
		if (fieldModelProperties && fieldModelProperties.lyteAtrrType) {
			switch (fieldModelProperties.lyteAtrrType) {
				case 'string':
					if (typeof fieldValue !== "string") {
						finalValue = (fieldValue !== null && fieldValue !== undefined) ? (fieldValue + "") : fieldValue;
					}
					break;
				case 'boolean':
					if (typeof fieldValue !== "boolean") {
						finalValue = fieldValue === 'true';
					}
					break;
			}
		}
		return finalValue;
	},
	getMapDependencyOptions: function (customData) {
		var { layoutComponentData, cxPropFieldData, isSubform, subformApiname } = customData, selectedLayoutid = layoutComponentData.cxPropLayoutId;
		var cxUtilityObj = layoutComponentData.cxInternalUtilityObj;
		var layoutFieldApiVsMetaObject = isSubform ? cxUtilityObj.subFormFieldApiVsMetaObject[subformApiname] : cxUtilityObj.layoutFieldApiVsMetaObject;
		var currentPickListOptions = cxPropFieldData[selectedLayoutid] && cxPropFieldData[selectedLayoutid].pick_list_values ? cxPropFieldData[selectedLayoutid].pick_list_values : cxPropFieldData.pick_list_values;
		var optionsLen = currentPickListOptions.length || 0, finalMapOptions = {};
		for (var k = 0; k < optionsLen; k++) {
			var currentOption = currentPickListOptions[k], mapArray = currentOption.maps || [];
			if (!mapArray.length) {
				break;
			} else {
				if (isSubform && cxUtilityObj.subformMapDependencyFields[subformApiname].indexOf(cxPropFieldData.api_name) === -1) {
					cxUtilityObj.subformMapDependencyFields[subformApiname].push(cxPropFieldData.api_name);
				} else if (cxUtilityObj.mapDependencyFields.indexOf(cxPropFieldData.api_name) === -1) {
					cxUtilityObj.mapDependencyFields.push(cxPropFieldData.api_name);
				}
				finalMapOptions[currentOption.display_value] = {};
			}
			mapArray.forEach(function (mapObject) {
				var mapDependencyFldApiname = mapObject.api_name;
				var mapFieldDetails = layoutFieldApiVsMetaObject[mapDependencyFldApiname];
				var mapPickListOptions = mapFieldDetails[selectedLayoutid] && mapFieldDetails[selectedLayoutid].pick_list_values ? mapFieldDetails[selectedLayoutid].pick_list_values : mapFieldDetails.pick_list_values;
				if (mapObject.pick_list_values && mapObject.pick_list_values.length) {
					var mapObjectOptnArr = [];
					mapObject.pick_list_values.forEach(option => mapObjectOptnArr.push(option.display_value));
					var validOriginalOption = mapPickListOptions.filter(function (orginalOption) {
						return mapObjectOptnArr.indexOf(orginalOption.display_value) !== -1;
					});
					finalMapOptions[currentOption.display_value][mapDependencyFldApiname] = validOriginalOption;
				} else {
					finalMapOptions[currentOption.display_value][mapDependencyFldApiname] = mapPickListOptions;
				}
			});
		}
		return { mapDependencyDetails: finalMapOptions };
	},
	setMapDependencyOption: function (customData) {
		var { isInitialRender, cxPropFormData, isSubform, subformApiname, layoutCompData, fieldValue, fieldMeta, currentInstObjKey } = customData;
		var cxUtilityObj = layoutCompData.cxInternalUtilityObj, instObj = fieldMeta[currentInstObjKey], mapDependencyDetails = instObj.mapDependencyDetails || {};
		if (Object.keys(mapDependencyDetails).length) {
			var layoutFieldApiVsMetaObject = isSubform ? cxUtilityObj.subFormFieldApiVsMetaObject[subformApiname] : cxUtilityObj.layoutFieldApiVsMetaObject;
			var selectedOptionMappings = instObj.mapDependencyDetails[fieldValue];
			if (!this.isEmptyObj(selectedOptionMappings)) {
				for (var childField in selectedOptionMappings) {
					var mapDepChildField = layoutFieldApiVsMetaObject[childField];
					if (mapDepChildField) {
						Lyte.Component.set(mapDepChildField[currentInstObjKey], 'pick_list_values', selectedOptionMappings[childField]);
						var skipValueUpdate = isInitialRender && cxUtilityObj.currentPage !== "create";
						if (!isSubform && !skipValueUpdate) {
							var finalDisplayValue = selectedOptionMappings[childField][0].display_value;
							if (mapDepChildField.data_type === "multiselectpicklist") {
								finalDisplayValue = [];
								if (cxPropFormData.hasOwnProperty(mapDepChildField.api_name)) {
									var existingValue = cxPropFormData[mapDepChildField.api_name] || [];
									var existingOptionsArray = selectedOptionMappings[childField].filter(eachOption => existingValue.indexOf(eachOption.display_value) !== -1);
									finalDisplayValue = existingOptionsArray.map(function (optObj) { return optObj.display_value; });
								}
								cxPropFormData[mapDepChildField.api_name] = finalDisplayValue;
							} else {
								if (cxPropFormData.hasOwnProperty(mapDepChildField.api_name)) {
									let existingValue = cxPropFormData[mapDepChildField.api_name];
									var existingOptionObject = selectedOptionMappings[childField].filter(eachOption => eachOption.display_value === existingValue)[0];
									finalDisplayValue = existingOptionObject ? cxPropFormData[mapDepChildField.api_name] : finalDisplayValue;
								}
								cxPropFormData[mapDepChildField.api_name] = finalDisplayValue;
							}
							Lyte.Component.set(mapDepChildField[currentInstObjKey], 'setValueIntoDom', !mapDepChildField[currentInstObjKey].setValueIntoDom);
							Lyte.Component.set(mapDepChildField[currentInstObjKey], 'triggerValueChangeCallback', !mapDepChildField[currentInstObjKey].triggerValueChangeCallback);
						}
					}
				}
			}
		}
	},
	setExchangeRateValue: function (customData) {
		var { cxPropFormData, currencyDetail, cxPropFieldData } = customData;
		if (cxPropFieldData.column_name === "EXCHANGERATE" && currencyDetail && currencyDetail.hasOwnProperty('er')) {
			Lyte.Component.set(cxPropFormData, cxPropFieldData.api_name, currencyDetail.er);
		}
	},
	handleCurrencyConversion: function (customData) {
		var { layoutCompData, cxPropFormData, selectedCurrency } = customData;
		var cxUtilityObj = layoutCompData.cxInternalUtilityObj, currentInstObjKey = layoutCompData.currentInstObjKey, exchangeRateFld = cxUtilityObj.layoutFieldApiVsMetaObject.Exchange_Rate, userCurrencyData = layoutCompData.cxPropUserCurrencyData;
		if (userCurrencyData && userCurrencyData.hasOwnProperty(selectedCurrency)) {
			var previousExchangeRate = cxPropFormData.Exchange_Rate, selectedCurrencyObj = userCurrencyData[selectedCurrency] || {};
			Lyte.Component.set(layoutCompData, 'cxPropCurrencyData', Lyte.deepCopyObject(selectedCurrencyObj));
			Lyte.Component.set(layoutCompData, 'cxPropCurrencySymbol', selectedCurrencyObj.symbol);
			Lyte.Component.set(layoutCompData, 'cxPropCurrencyKey', selectedCurrency);
			var subformLayoutCurrencyFields = cxUtilityObj.subformLayoutCurrencyFields || {};
			var currencyParamObject = { currentInstObjKey, selectedCurrencyObj, previousExchangeRate };
			for (var subform_apiname in subformLayoutCurrencyFields) {
				var subCurrencyFields = subformLayoutCurrencyFields[subform_apiname] || [], currSubformRecords = cxPropFormData[subform_apiname] || [];
				currSubformRecords.forEach(function (subformRecords) {
					subCurrencyFields.forEach(function (currencyField) {
						this.setConvertedValueInRecord(Object.assign({ currencyField: currencyField, formData: subformRecords }, currencyParamObject));
					}.bind(this));
				}.bind(this));
			}
			var layoutCurrencyFields = cxUtilityObj.layoutCurrencyFields || [];
			layoutCurrencyFields.forEach(function (parentCurrencyField) {
				this.setConvertedValueInRecord(Object.assign({ currencyField: parentCurrencyField, formData: cxPropFormData }, currencyParamObject));
				Lyte.Component.set(parentCurrencyField[currentInstObjKey], 'setValueIntoDom', !parentCurrencyField[currentInstObjKey].setValueIntoDom);
			}.bind(this));
			this.setExchangeRateValue({ cxPropFormData, currencyDetail: selectedCurrencyObj, cxPropFieldData: exchangeRateFld });
			Lyte.Component.set(exchangeRateFld[currentInstObjKey], 'setValueIntoDom', !exchangeRateFld[currentInstObjKey].setValueIntoDom);
		}
	},
	setConvertedValueInRecord: function (currencyParamObject) {
		var { formData, currencyField, currentInstObjKey, selectedCurrencyObj, previousExchangeRate } = currencyParamObject;
		var currencyFieldApiname = currencyField.api_name, previousCurrencyValue = formData[currencyFieldApiname];
		var convertedValue = this.getConvertedCurrencyValue({ previousCurrencyValue, previousExchangeRate, selectedCurrencyObj });
		if (!isNaN(convertedValue)) {
			convertedValue = Number(convertedValue).toFixed(selectedCurrencyObj.decimals);
			var fieldDecimalPlaceValue = currencyField[currentInstObjKey].decimal_place;
			convertedValue = (selectedCurrencyObj.decimals !== undefined && fieldDecimalPlaceValue !== undefined && selectedCurrencyObj.decimals > fieldDecimalPlaceValue) ? Number(convertedValue).toFixed(fieldDecimalPlaceValue) : convertedValue;
			Lyte.Component.set(formData, currencyFieldApiname, convertedValue + "");
		}
	},
	getConvertedCurrencyValue: function (customData) {
		var { previousCurrencyValue, previousExchangeRate, selectedCurrencyObj } = customData;
		return (previousCurrencyValue / previousExchangeRate) * selectedCurrencyObj.er;
	},
	getLookupIconClass: function (lookupFieldData) {
		if (["multiselectlookup", "lookup"].indexOf(lookupFieldData.data_type) === -1) {
			return "";
		}
		var corres_moduleName, isCustommodule = false, returnValue = "cxModulecustomModule", havemoduleId = true;
		if (lookupFieldData.data_type === 'multiselectlookup') {
			corres_moduleName = lookupFieldData.multiselectlookup.connected_module.api_name;
			if (!lookupFieldData.multiselectlookup.connected_module.id) {
				havemoduleId = false;
			}
			if (lookupFieldData.multiselectlookup.connected_module && lookupFieldData.multiselectlookup.connected_module.hasOwnProperty("generated_type")) {
				isCustommodule = lookupFieldData.multiselectlookup.connected_module.generated_type === 'default' ? false : true;//no i18n
			}
		} else if (lookupFieldData.data_type === 'lookup') {
			corres_moduleName = lookupFieldData.lookup.module.api_name;
			if (!lookupFieldData.lookup.module.id) {
				havemoduleId = false;
			}
			if (lookupFieldData.lookup.module && lookupFieldData.lookup.module.hasOwnProperty("generated_type")) {
				isCustommodule = lookupFieldData.lookup.module.generated_type === 'default' ? false : true;//no i18n
			}
		}
		if (isCustommodule === false) {
			returnValue = !havemoduleId ? "cxModuleSearchicon" : 'cxModule' + corres_moduleName;//no i18n
		} else {
			returnValue = !havemoduleId ? "cxModuleSearchicon" : "cxModulecustomModule";	//no i18n
		}
		return returnValue;
	},
	processFieldsOfLookup: function (customData) {
		var fieldMeta = customData.fieldMeta;
		var isFOLSupportedField = ["lookup", "ownerlookup", "userlookup"].indexOf(fieldMeta.data_type) !== -1 ? true : false;
		if (!isFOLSupportedField) { return; };
		var { selectedLookupRecord, layoutCompData, cxPropFormData, currentInstObjKey, isSubform, subformApiname } = customData;
		var cxUtilityObj = layoutCompData.cxInternalUtilityObj;
		var currentField = fieldMeta.api_name, folDetails = (isSubform ? (cxUtilityObj.subformFieldOfLookupDetails && cxUtilityObj.subformFieldOfLookupDetails[subformApiname]) : cxUtilityObj.fieldOfLookupDetails) || {};
		if (folDetails.hasOwnProperty(currentField)) {
			var dataObject = { selectedLookupRecord, fieldMeta, cxPropFormData, currentInstObjKey, isSubform, subformApiname };
			dataObject.fieldOfLookupDetails = folDetails[currentField];
			var folCallBackResponse = this.invokeCruxFormCallBacks({ callbackEventName: 'formFieldOfLookup', formFieldOfLookup: dataObject });//no i18n
			folCallBackResponse.then(function (promiseResponse) {
				let skippedExecution = true;
				if (promiseResponse === false) {
					return skippedExecution;
				}
			}.bind(this));
		}
	},
	setFormData: function (customData) {
		customData = customData || {};
		var cruxFormData = customData.cruxFormData || {}, layoutCompData = this.data.cxPropLayoutComponentData, currentInstObjKey = layoutCompData.currentInstObjKey;
		var formDataKeys = Object.keys(cruxFormData) || [];
		formDataKeys.forEach(function (key) {
			var fieldMeta = layoutCompData.cxInternalUtilityObj.layoutFieldApiVsMetaObject[key];
			if (!fieldMeta) { return; }
			var fieldApiname = fieldMeta.api_name;
			if (fieldMeta.data_type !== "subform") {
				Lyte.Component.set(layoutCompData.cxPropFormData, fieldApiname, cruxFormData[fieldApiname]);
				Lyte.Component.set(fieldMeta[currentInstObjKey], 'setValueIntoDom', !fieldMeta[currentInstObjKey].setValueIntoDom);
			} else {
				this.setSubformData({ fieldApiname, newSubformData: cruxFormData[fieldApiname], cxPropFormData: layoutCompData.cxPropFormData });
			}
		}.bind(this));
	},
	setSubformData: function (customData) {
		let { cxPropFormData, newSubformData, fieldApiname } = customData,
			currentSubformData = cxPropFormData[fieldApiname] || [],
			isValueArray = Array.isArray(newSubformData);
		let subformNodeData = this.getSubformNode(fieldApiname);
		if (newSubformData === null || (isValueArray && !newSubformData.length)) {
			let currentSubformDataLen = currentSubformData.length;
			while (currentSubformDataLen > 0) {
				if (subformNodeData.cruxSubformNode) {
					subformNodeData.cruxSubformNode.deleteRow(0);
				}
				currentSubformDataLen--;
			}
		} else if (isValueArray) {
			for (const [index, eachSubformRecord] of newSubformData.entries()) {
				if (!currentSubformData[index] && subformNodeData.cruxSubformNode) {
					subformNodeData.cruxSubformNode.createRow(eachSubformRecord);
				}
				if (currentSubformData[index]) {
					let layoutComponentData = this.data.cxPropLayoutComponentData, cxUtilityObj = layoutComponentData.cxInternalUtilityObj;
					if (cxUtilityObj.subFormFieldApiVsMetaObject && Object.keys(cxUtilityObj.subFormFieldApiVsMetaObject).length) {
						let subormMetaDetails = cxUtilityObj.subFormFieldApiVsMetaObject[fieldApiname] || {};
						for (var sKey in eachSubformRecord) {
							if (subormMetaDetails[sKey]) {
								Lyte.Component.set(currentSubformData[index], sKey, eachSubformRecord[sKey]);
							}
						}
					}
				}
			}
		}
	},
	getComponentInitData: function (customData) {
		var { customFieldComponentData, requiredCompDataProperties, isSubform } = customData;
		requiredCompDataProperties = requiredCompDataProperties || ['cxPropFieldData', 'cxPropFormData', 'cxPropLayoutComponentData', 'currentInstObjKey'];
		var returnObj = {}, yieldGlobalData = this.data.yieldGlobalData;
		customFieldComponentData = customFieldComponentData || {};
		var { cxPropFormData, cxPropFieldData, cxPropLayoutComponentData, currentInstObjKey } = this.data;
		if (isSubform) {
			cxPropFormData = this.data.cxPropSubformRowData;
			returnObj.parentFormData = this.data.cxPropFormData;
		}
		var yieldLayoutGlobalData = cxPropLayoutComponentData.yieldLayoutGlobalData || {};
		requiredCompDataProperties.forEach(function (dataProperty) {
			if (customFieldComponentData.hasOwnProperty(dataProperty)) {
				returnObj[dataProperty] = customFieldComponentData[dataProperty];
			} else {
				switch (dataProperty) {
					case 'lyteViewPort':
						returnObj[dataProperty] = isSubform ? false : true;
						break;
					case 'fromSubform':
						returnObj[dataProperty] = isSubform ? true : false;
						break;
					case 'currentInstObjKey':
						returnObj[dataProperty] = currentInstObjKey;
						break;
					case 'fieldsCurntInstObj':
						returnObj[dataProperty] = cxPropFieldData[currentInstObjKey];
						break;
					case 'cxPropLayoutComponentData':
						returnObj[dataProperty] = cxPropLayoutComponentData;
						break;
					case 'fieldlabelMaxwidth':
						returnObj[dataProperty] = yieldGlobalData && (yieldGlobalData.containerName === 'singleContainer' ? yieldLayoutGlobalData.singleContainerfieldLabelWidth : yieldGlobalData.containerName === 'leftContainer' ? yieldLayoutGlobalData.container1fieldLabelWidth : yieldLayoutGlobalData.container2fieldLabelWidth);
						break;
					case 'createFieldsClass':
						returnObj[dataProperty] = yieldGlobalData && yieldGlobalData.containerName ? (yieldGlobalData.containerName + (isSubform ? 'subformCrtField' : 'CrtField')) : '';//no i18n
						break;
					case 'moduledataUicomp':
					case 'cxPropFieldData':
						returnObj[dataProperty] = cxPropFieldData;
						break;
					case 'cxPropFormData':
						returnObj[dataProperty] = cxPropFormData;
						break;
				}
			}
		}.bind(this));
		return returnObj;
	},
	getInitialFormDataForClone: function (originalFormData) {
		let layoutComponentData = this.data.cxPropLayoutComponentData, cxUtilityObj = layoutComponentData.cxInternalUtilityObj;
		function createEntityobject(originalFormData) {
			let currentSubforms = Object.keys(cxUtilityObj.subformFieldList || {}),
				newSubformRecords = {};
			if (currentSubforms.length) {
				currentSubforms.forEach((subformApiName) => {
					newSubformRecords[subformApiName] = [];
					if (originalFormData[subformApiName] && originalFormData[subformApiName].length) {
						originalFormData[subformApiName].forEach((subformRecord) => {
							let newSubRec = subformRecord.$.toJSON();
							newSubRec.mappingID = subformRecord.id;
							if (["Invoiced_Items", "Purchase_Items", "Ordered_Items", "Quoted_Items"].includes(subformApiName)) {
								newSubRec.$clone_reference_id = subformRecord.id;
							}
							delete newSubRec.id; delete newSubRec.__parent_module__;
							newSubformRecords[subformApiName].push(store.createRecord(subformRecord.$.model._name, newSubRec, true));
						});
					}
				});
				this.data.subfRecordcloned = newSubformRecords;
			}
			originalFormData = originalFormData.$.toJSON();
			delete originalFormData.__parent_module__; delete originalFormData.id;
			if (!this.isEmptyObj(newSubformRecords)) {
				for (var sKey in newSubformRecords) {
					originalFormData[sKey] = newSubformRecords[sKey];
				}
			}
			return originalFormData;
		};
		function handleCloneReadOnlyFields(originalFormData) {
			let formFields = cxUtilityObj.layoutFieldApiVsMetaObject;
			for (var fKey in formFields) {
				let eachField = formFields[fKey],
					fldVisiblityCustomData = { currentPage: cxUtilityObj.currentPage };
				fldVisiblityCustomData.columnsToSkip = layoutComponentData.columnsToSkip || [];
				fldVisiblityCustomData.apiNamesToSkip = layoutComponentData.apiNamesToSkip || [];
				fldVisiblityCustomData.uiTypeToSkip = layoutComponentData.uiTypeToSkip || [];
				let isValidField = this.isValidFieldToRender(eachField, fldVisiblityCustomData),
					systemReadOnlyFlds = ["EXPECTEDREVENUE"],//no i18n
					moduleName = this.data.cxPropModuleData && this.data.cxPropModuleData.module_name;
				if (isValidField && eachField.read_only && eachField.data_type !== "subform" && eachField.data_type !== "formula" && !(systemReadOnlyFlds.includes(eachField.column_name) && moduleName !== "Campaigns")) {
					originalFormData[eachField.api_name] = null;
				} else if (eachField.data_type === "subform") { //no i18n
					let subformData = originalFormData[eachField.api_name];
					if (subformData && subformData.length) {
						let currentSubformsFields = cxUtilityObj.subFormFieldApiVsMetaObject && cxUtilityObj.subFormFieldApiVsMetaObject[eachField.api_name] || {};
						for (var sKey in currentSubformsFields) {
							let eachSField = currentSubformsFields[sKey];
							let isValidSField = this.isValidFieldToRender(eachSField, fldVisiblityCustomData),
								isValidVRFieldForInventory = isValidSField ? this.isValidVRFieldForInventory(eachSField, moduleName) : false;
							if (isValidSField && (eachSField.read_only && !isValidVRFieldForInventory) && eachSField.data_type !== "subform" && eachSField.data_type !== "formula") {
								subformData.forEach((subRow) => {
									if (["Invoiced_Items", "Purchase_Items", "Ordered_Items", "Quoted_Items"].includes(eachField.api_name) && eachSField.column_name === "DISCOUNT") {
										subRow[eachSField.api_name] = 0;
									} else {
										subRow[eachSField.api_name] = null;
									}
								});
							}
						}
					}
				}
			}
			return originalFormData;
		};
		let returnObject = {};
		try {
			returnObject = Lyte.deepCopyObject(originalFormData);
		} catch (e) {
			returnObject = originalFormData;
		}
		returnObject = createEntityobject.call(this, originalFormData);
		returnObject = handleCloneReadOnlyFields.call(this, returnObject);
		let cloneRecord = store.createRecord(this.data.cxPropModuleId, returnObject ? returnObject : {}, true);
		return cloneRecord;
	},
	getCruxFormData: function (originalData) {
		var cxPropFormData = {}, layoutCompData = this.data.cxPropLayoutComponentData, cxUtilityObj = layoutCompData.cxInternalUtilityObj, currentPage = cxUtilityObj.currentPage;
		if (typeof Record !== 'undefined' && originalData && originalData.$) {
			return originalData;
		}
		if (this.isEmptyObj(originalData)) {
			return cxPropFormData;
		}
		var parentModuleFieldLists = cxUtilityObj.formFieldList || {};
		var subformFieldList = cxUtilityObj.subformFieldList || {};
		function getFinalData(fldList, orgRecData, finalFormData) {
			for (var fieldApiName in orgRecData) {
				if ((currentPage === "edit" && ['id'].includes(fieldApiName)) || (fldList.hasOwnProperty(fieldApiName) && !subformFieldList.hasOwnProperty(fieldApiName))) {
					finalFormData[fieldApiName] = typeof orgRecData[fieldApiName] === 'object' && orgRecData[fieldApiName] ? Lyte.deepCopyObject(orgRecData[fieldApiName]) : orgRecData[fieldApiName];
				}
			}
		}
		getFinalData(parentModuleFieldLists, originalData, cxPropFormData);
		for (var sKeys in subformFieldList) {
			var currentSubformFieldList = subformFieldList[sKeys] || {}; cxPropFormData[sKeys] = [];
			let allSubRecs = originalData[sKeys] || [];
			allSubRecs.forEach(function (eachRecord) {
				var subformData = {};
				getFinalData(currentSubformFieldList, eachRecord, subformData);
				cxPropFormData[sKeys].push(subformData);
			});
		}
		return cxPropFormData;
	},
	getFinalFormData: function () {
		var layoutComponentData = this.data.cxPropLayoutComponentData, cxUtilityObj = layoutComponentData.cxInternalUtilityObj,
			formData = layoutComponentData.cxPropFormData,
			subformFieldList = cxUtilityObj.subformFieldList,
			parentFieldList = cxUtilityObj.formFieldList;
		let isRecordObject = false;
		if (formData && formData.$ && formData.$.model) {
			isRecordObject = true;
		}
		var finalFormData = isRecordObject ? formData : Lyte.deepCopyObject(formData),
			skipTypeConversion = layoutComponentData.skipTypeConversion;
		if (!skipTypeConversion) {
			var jsonTypeConversionFields = cxUtilityObj.jsonTypeConversionFields || [];
			jsonTypeConversionFields.forEach(function (fieldApiname) {
				let cData = { formData: finalFormData, fieldApiname, formFieldList: parentFieldList, recordErrorObject: isRecordObject ? formData.$.error : {} };
				cData.fieldFullMeta = cxUtilityObj.layoutFieldApiVsMetaObject[fieldApiname];
				if (formData.hasOwnProperty(fieldApiname) || cData.recordErrorObject.hasOwnProperty(fieldApiname)) {
					var convertedNumberVal = this.getTypeConvertedValue(cData);
					Lyte.Component.set(finalFormData, fieldApiname, convertedNumberVal);
				}
			}.bind(this));
			if (!this.isEmptyObj(subformFieldList)) {
				for (var skey in subformFieldList) {
					if (finalFormData[skey]) {
						var subformRecords = finalFormData[skey] || [];
						var subnumberJsonTypeFields = (cxUtilityObj.subformJsonTypeConversionFields && cxUtilityObj.subformJsonTypeConversionFields[skey]) || [];
						subformRecords.forEach(function (sRec) {
							subnumberJsonTypeFields.forEach(function (fieldApiname) {
								var cData = { formData: sRec, fieldApiname, formFieldList: subformFieldList[skey], recordErrorObject: isRecordObject ? sRec.$.error : {} };
								cData.fieldFullMeta = cxUtilityObj.subFormFieldApiVsMetaObject[skey] && cxUtilityObj.subFormFieldApiVsMetaObject[skey][fieldApiname];
								if (sRec.hasOwnProperty(fieldApiname) || cData.recordErrorObject.hasOwnProperty(fieldApiname)) {
									var convertedNumberVal = this.getTypeConvertedValue(cData);
									Lyte.Component.set(sRec, fieldApiname, convertedNumberVal);
								}
							}.bind(this));
						}.bind(this));
					}
				}
			}
		}
		return finalFormData;
	},
	getTypeConvertedValue: function (customData) {
		var { formData, fieldApiname, fieldFullMeta, formFieldList, recordErrorObject } = customData, returnValue = formData[fieldApiname], fldListObject = formFieldList[fieldApiname] || {};
		recordErrorObject = recordErrorObject || {};
		let fieldValue = recordErrorObject.hasOwnProperty(fieldApiname) ? recordErrorObject[fieldApiname].value : formData[fieldApiname];
		switch (fldListObject.fieldDataType) {
			case 'multiselectpicklist':
				if (Array.isArray(fieldValue)) {
					returnValue = JSON.stringify(fieldValue);
				} else if (typeof fieldValue === "string") {
					try {
						if (JSON.parse(fieldValue)) {
							returnValue = fieldValue;
						}
					} catch (e) {
						returnValue = fieldValue ? JSON.stringify(fieldValue.split('; ')) : JSON.stringify([]);
					}
				}
				break;
			case 'multiuserlookup':
				returnValue = fieldValue;
				if (formData.$ && formData.$.model && formData.$.model.fieldList[fieldApiname]) {
					let connectedApiname = fieldFullMeta.multiuserlookup.connectedlookup_apiname || fieldApiname;
					try {
						if (Array.isArray(fieldValue)) {
							let finalArr = [];
							fieldValue.forEach(userObj => {
								let finObj = {};
								finObj[connectedApiname] = { id: userObj.id };
								finObj[connectedApiname].name = userObj.full_name || userObj.name;
								finalArr.push(finObj);
							});
							returnValue = finalArr && finalArr.length ? { users: finalArr } : {};
						}
					} catch (exception) {
						returnValue = fieldValue;
					}
				}
				break;
			default:
				if (!isNaN(fieldValue)) {
					returnValue = Number(fieldValue);
				}
		}
		return returnValue;
	},
	appendInsObjectProperties: function (customData) {
		var { fieldsArray, currentInstObjKey, fieldMeta, isSubform, subformFieldList } = customData, _this = this;
		var layoutComponentData = this.data.cxPropLayoutComponentData, cxUtilityObj = layoutComponentData.cxInternalUtilityObj;
		fieldsArray = fieldsArray || [];
		var formFieldList = isSubform ? subformFieldList : cxUtilityObj.formFieldList;
		function fillFldProperties(eachField) {
			if (_this.isEmptyObj(eachField[currentInstObjKey])) {
				eachField[currentInstObjKey] = {};
			}
			var instObjSubFld = eachField[currentInstObjKey], fieldUiType = eachField.ui_type, cruxType = "text", cxPropType;
			var instanceObjKeys = ["required", "read_only", "view_type", "tab_index", "length", "visible", "decimal_place"], instanceObjKeysLen = instanceObjKeys.length;
			for (var n = 0; n < instanceObjKeysLen; n++) {
				var fieldProp = instanceObjKeys[n];
				if (!instObjSubFld.hasOwnProperty(fieldProp)) {
					let _value = eachField[fieldProp];
					if (eachField.ui_type === 208 && fieldProp === 'read_only' && ['clone', 'edit'].includes(cxUtilityObj.currentPage)) {
						_value = true;
					}
					instObjSubFld[fieldProp] = typeof _value === 'object' && _value ? Lyte.deepCopyObject(_value) : _value;
				}
			}
			var defaultUiTypeToCruxMapping = cxUtilityObj.defaultUiTypeToCruxMapping;
			switch (fieldUiType) {
				case 35:
					cruxType = "phone";//No I18n
					break;
				case 445:
					cruxType = "user";
					cxPropType = "multiple";//No I18n
					break;
				case 100:
					cruxType = "picklist";
					cxPropType = "multisearch";//No I18n
					break;
				case 556:
					cruxType = "imageupload";
					break;
				case 555:
					cruxType = "fileupload";
					break;
				case 444:
					cruxType = "multiselectlookup";
					break;
				case 208:
					cruxType = "text";
					break;
				default:
					if (defaultUiTypeToCruxMapping && defaultUiTypeToCruxMapping[fieldUiType]) {
						cruxType = defaultUiTypeToCruxMapping[fieldUiType];
					}
					break;
			}
			instObjSubFld.cruxType = cruxType;
			instObjSubFld.cxPropType = cxPropType;
			if (!formFieldList[eachField.api_name]) {
				formFieldList[eachField.api_name] = {};
			}
			var fieldListRequiredProperty = {
				mandatory: 'required', fieldLength: 'length', fieldId: 'id', fieldDataType: 'data_type',
				fieldUiType: 'ui_type', displayLabel: 'display_label', fieldLabel: 'field_label', isCustomField: 'custom_field',
				columnName: 'column_name', decimalPlace: 'decimal_place', json_type: 'json_type', lyteAtrrType: 'json_type'
			};
			for (var key in fieldListRequiredProperty) {
				formFieldList[eachField.api_name][key] = eachField[fieldListRequiredProperty[key]];
				if (key === "fieldDataType" && eachField[fieldListRequiredProperty[key]] === "subform") {
					formFieldList[eachField.api_name].isSubformRelation = true;
				}
				if (key === "lyteAtrrType") {
					switch (eachField[fieldListRequiredProperty[key]]) {
						case 'double':
						case 'integer':
							formFieldList[eachField.api_name][key] = "string";//no i18n
							break;
						case 'jsonobject':
							formFieldList[eachField.api_name][key] = "object";//no i18n
							break;
						case 'jsonarray':
							formFieldList[eachField.api_name][key] = "array";//no i18n
							break;
					}
					if (eachField.ui_type === 208 && key === 'lyteAtrrType') {
						formFieldList[eachField.api_name][key] = "object";//no i18n
					}
				}
			}
			_this.addCustomValidationsforField({ formFieldList, cxPropFieldData: eachField });
		};
		if (fieldMeta) {
			fillFldProperties(fieldMeta);
		} else {
			fieldsArray.forEach(function (indvField) {
				fillFldProperties(indvField);
			});
		}
	},
	getDefaultModalMaxWidth: function (width, quickCreateColumnCount) {
		var fieldWidth = 390,
			paddingLeftRight = 60,
			scrollBarWidth = 15,
			actualFieldWidth = width || 180,
			modalWidth = fieldWidth + paddingLeftRight + actualFieldWidth + scrollBarWidth;
		modalWidth = quickCreateColumnCount === 2 ? (modalWidth * 2) : modalWidth;
		return `${modalWidth}px`;
	},
	constructLyteUiComponentProperties: function (customData) {
		let { layoutCompData, cxPropRenderMode } = customData,
			cxUtilityObj = layoutCompData.cxInternalUtilityObj;
		if (cxPropRenderMode === "modal") {
			let givenModalProps = layoutCompData.cxPropModalProperties || {},
				defaultModalProps = {
					show: true,
					wrapperClass: `${cxUtilityObj.cxCreateFormContentWrapperClass}_modal_wc`,
					preventFocus: 'true',
					transition: '{"animation":"slideFromTop","duration":"0.1"}',//no i18n
					offset: '{"top":"0","left":"center"}',//no i18n
					maxWidth: this.getDefaultModalMaxWidth(cxUtilityObj.fieldLabelMaxWidth, cxUtilityObj.quickCreateColumnCount),
					maxHeight: "95%",
					allowMultiple: 'true',
					scrollable: 'true',
					showCloseButton: 'false',
					closeOnEscape: 'true'
				};
			for (var propertyKey in defaultModalProps) {
				if (!givenModalProps.hasOwnProperty(propertyKey)) {
					givenModalProps[propertyKey] = defaultModalProps[propertyKey];
				} else if (propertyKey === 'wrapperClass') {
					givenModalProps[propertyKey] = `${givenModalProps[propertyKey]} ${defaultModalProps[propertyKey]}`;
				}
			}
			cxUtilityObj.cxPropModalProperties = JSON.stringify(Object.assign({}, givenModalProps));
		} else if (cxPropRenderMode === "popover") {
			let givenPopoverProps = layoutCompData.cxPropPopoverProperties || {},
				defaultPopoverProps = {
					show: true,
					type: "callout",
					freeze: true,
					showCloseButton: true,
					closeOnEscape: true,
					originElem: "",
					placement: "bottom",
					dimmer: { "color": "#000", "opacity": "0.4" },
					draggable: false,
					allowMultiple: false,
					scrollable: false,
					maxHeight: "70%",
					maxWidth: "50%",
					width: "40%",
					height: "auto",
					wrapperClass: `${cxUtilityObj.cxCreateFormContentWrapperClass}_popover_wc`,
					preventFocus: "true",
					offset: { offset: { "top": "50px", "left": "500px", "height": "50px", "width": "500px" } }
				};
			for (var property_key in defaultPopoverProps) {
				if (!givenPopoverProps.hasOwnProperty(property_key)) {
					givenPopoverProps[property_key] = defaultPopoverProps[property_key];
				} else if (property_key === 'wrapperClass') {
					givenPopoverProps[property_key] = `${givenPopoverProps[property_key]} ${defaultPopoverProps[property_key]}`;
				}
			}
			cxUtilityObj.cxPropPopoverProperties = JSON.stringify(Object.assign({}, givenPopoverProps));
		}
	},
	getDefaultLayoutDetails: function (moduleData, profileName, cxPropLayoutId) {
		let currentProfileName = profileName || (typeof Crm !== 'undefined' && Crm.userDetails.PROFILE_NAME) || "Administrator",//no i18n
			layDet = moduleData.layouts || [],
			currentLayoutName,
			currentLayoutId,
			layoutddValues = [],
			showIntegrationLayoutDD = false,
			module_name = moduleData.module_name;
		if (layDet && layDet.length) {
			layDet = Lyte.deepCopyObject(layDet);//ZCRM-118843
			layDet = layDet.sort((layout1, layout2) => {
				let name1 = layout1.name.toUpperCase(),
					name2 = layout2.name.toUpperCase();
				if (name1 < name2) {
					return -1;
				}
				if (name1 > name2) {
					return 1;
				}
				return 0;
			});
		}
		let len = layDet && layDet.length || 0;
		for (var i = 0; i < len; i++) {
			if (layDet[i].status >= 0 || layDet[i].status === true || layDet[i].status === "active") {
				showIntegrationLayoutDD = module_name === "Campaigns" && layDet[i].status === "active" && layDet[i].source === "campaign_integration" ? true : showIntegrationLayoutDD;//no i18n
				let isValidLayout = false;
				if (layDet[i].profiles) {
					var profL = layDet[i].profiles.length, isDefault = false;
					for (var c = 0; c < profL; c++) {
						if (currentProfileName === layDet[i].profiles[c].name) {
							isValidLayout = true;
							if (layDet[i].profiles[c].default) {
								currentLayoutName = layDet[i].name;
								currentLayoutId = layDet[i].id;
								isDefault = true;
								break;
							}
						}
					}
					if (isValidLayout) {
						let layoutDetailsObj = {
							uservalue: layDet[i].name,
							systemvalue: layDet[i].id,
							id: layDet[i].id,
							isDefault: isDefault,
							status: layDet[i].status,
							source: layDet[i].source
						};
						layoutddValues.push(layoutDetailsObj);
					}
				}
			}
		}
		if (cxPropLayoutId) {
			currentLayoutId = cxPropLayoutId;
			let currentLayoutDetails = layoutddValues.filter((option) => { return option.id === currentLayoutId; })[0];
			currentLayoutName = (currentLayoutDetails && currentLayoutDetails.uservalue) || currentLayoutName;
		}
		if (module_name === "Campaigns" && cxPropLayoutId) {
			let currLayout = layoutddValues.filter((f) => { return f.id === cxPropLayoutId; })[0];
			if (currLayout) {
				currentLayoutName = currLayout.uservalue;
				currentLayoutId = currLayout.id;
			}
		}
		if (!currentLayoutId && layoutddValues && layoutddValues.length) { //when none of the layout is default layout for the profile
			if (module_name === "Campaigns") {
				let layoutToRender = layoutddValues.filter((lay) => { return lay.source === "crm"; })[0];
				layoutToRender = layoutToRender || layoutddValues[0];
				currentLayoutName = layoutToRender.name;
				currentLayoutId = layoutToRender.id;
				layoutToRender.isDefault = true;
			} else {
				currentLayoutName = layoutddValues[0].name;
				currentLayoutId = layoutddValues[0].id;
				layoutddValues[0].isDefault = true;
			}
		}
		return { layoutddValues, showIntegrationLayoutDD, currentLayoutName, currentLayoutId };
	},
	setFieldOfLookupData: function (customData) {
		customData = customData || {};
		let folDetails = customData.fieldOfLookupDetails || [],
			lookupRecord = customData.selectedLookupRecord || {},
			setDataObject = {};
		if (this.isEmptyObj(lookupRecord)) {
			return;
		}
		// if (lookupRecord && lookupRecord._$ && lookupRecord._$.original) {
		// 	originalValues = lookupRecord._$.original;
		// }
		// originalValues = originalValues || {};
		folDetails.forEach((details) => {
			let { currentFormField, lookupModuleMapField } = details,
				fieldValue = lookupRecord[lookupModuleMapField.api_name];
			/*
			if(originalValues.hasOwnProperty(lookupModuleMapField.api_name)){
				fieldValue = originalValues[lookupModuleMapField.api_name];
			}
			*/
			setDataObject[currentFormField] = fieldValue;
		});
		setDataObject = setDataObject || {};
		if (customData.layoutComponentDomNode) {
			customData.layoutComponentDomNode.setFormData({ cruxFormData: setDataObject });
		}
	},
	getNewLayoutRenderingObj: function (layoutId, cxPropLayoutComponentData) {
		var originalLayoutComponentData, newRecord = {};
		if (cxPropLayoutComponentData.cxPropFormData && cxPropLayoutComponentData.cxPropFormData.$) {
			let moduleId = cxPropLayoutComponentData.cxPropModuleId || (cxPropLayoutComponentData.cxPropModuleData && cxPropLayoutComponentData.cxPropModuleData.id);
			newRecord = store.createRecord(moduleId, {}, true);
		}
		if (cxPropLayoutComponentData.layoutComponentDomNode) {
			let copiedObj = (cxPropLayoutComponentData.layoutComponentDomNode.component.data.originalLayoutComponentData || {});
			originalLayoutComponentData = Lyte.deepCopyObject(copiedObj);
		}
		if (!originalLayoutComponentData.hasOwnProperty('cxPropRenderMode')) {
			originalLayoutComponentData.cxPropRenderMode = cxPropLayoutComponentData.cxPropRenderMode;
		}
		let newLayoutRenderingObj = Object.assign({}, originalLayoutComponentData);
		if (layoutId) { newLayoutRenderingObj.cxPropLayoutId = layoutId; }
		delete newLayoutRenderingObj.cxPropLayoutData; delete newLayoutRenderingObj.cxPropLayoutSections;
		newLayoutRenderingObj.cxPropFormData = newRecord;
		return newLayoutRenderingObj;
	},
	destroyComponent: function (layoutComponentData, reRender) {
		if (["popover", "modal"].includes(layoutComponentData.cxPropRenderMode)) {
			let renderMode;
			if (layoutComponentData.cxPropRenderMode === "modal") {
				renderMode = "Modal";
			} else if (layoutComponentData.cxPropRenderMode === "popover") {
				renderMode = "Popover";
			}
			let elementId = `#cruxCreateForm${renderMode}${layoutComponentData.currentInstObjKey}`, elemntNode = $L(elementId)[0];
			if (elemntNode) {
				elemntNode.remove();
			}
		}
		if (layoutComponentData.layoutComponentDomNode) {
			layoutComponentData.layoutComponentDomNode.remove();
		}
		if (layoutComponentData.crmCruxCreateFormDomNode && !reRender) {
			layoutComponentData.crmCruxCreateFormDomNode.remove();
		}
	},
	isQuickCreateButtonNeeded: function (layoutComponentData, cxPropFieldData) {
		let showQCButton = false, currentInstObjKey = layoutComponentData.currentInstObjKey;
		if (layoutComponentData.isQuickCreate) {
			showQCButton = false;
		} else {
			if (layoutComponentData.hasOwnProperty('isLookupQuickCreateSupported')) {
				return layoutComponentData.isLookupQuickCreateSupported;
			} else if (cxPropFieldData[currentInstObjKey] && cxPropFieldData[currentInstObjKey].hasOwnProperty('isLookupQuickCreateSupported')) {
				return cxPropFieldData[currentInstObjKey].isLookupQuickCreateSupported;
			}
			showQCButton = true;
			if (cxPropFieldData.lookup && cxPropFieldData.lookup.module && cxPropFieldData.lookup.module.api_name &&
				['Quotes', 'Purchase_Orders', 'Sales_Orders', 'Invoices', 'Price_Books', 'Services__s'].includes(cxPropFieldData.lookup.module.api_name)) {
				showQCButton = false;
			}
		}
		return showQCButton;
	},
	getCurrentLookupNode: function (customData = {}) {
		let { cruxLookupElmId, fromSubform } = customData,
			qSel = `${fromSubform ? `#${cruxLookupElmId}` : 'crux-lookup-component'}`;
		return this.$node.querySelector(qSel);
	},
	showLookupModal: function (customData = {}) {
		let lookupNode = this.getCurrentLookupNode(customData);
		if (lookupNode) {
			lookupNode.showLookup();
		}
	},
	hideLookupDropdown: function (customData = {}) {
		let { cruxLookupElmId, fromSubform } = customData;
		let qSel = `${fromSubform ? `#${cruxLookupElmId}` : 'crux-lookup-component'} lyte-autocomplete lyte-dropdown`,//no i18n
			ddNode = this.$node.querySelector(qSel);
		if (ddNode) {
			ddNode.close();
		}
	},
	hideLookupModal: function (customData = {}) {
		let lookupNode = this.getCurrentLookupNode(customData);
		if (lookupNode) {
			lookupNode.close();
		}
	},
	lookupCallBackHandler: function (dataObj = {}, subformData = {}) {
		let currentButtonObj = dataObj.currentButtonObj,
			fieldMeta = subformData.cxPropFieldData || this.data.cxPropFieldData,
			newRecordDetails = dataObj.quickCreatedRecord;
		if (["cancel", "save"].includes(currentButtonObj.name) && (this.quickCreateFromModal || currentButtonObj.name === "save")) {
			this.showLookupModal(subformData);
		} else if (currentButtonObj.name === "saveandassociate" && newRecordDetails) {
			let layoutCompData = this.data.cxPropLayoutComponentData,
				lookupModuleId = fieldMeta.lookup.module.id,
				currentApiname = fieldMeta.api_name,
				lookupModuleInfo = layoutCompData.cxInternalUtilityObj.lookupModuleMetaInfo,
				fieldValue = { id: newRecordDetails.id };

			if (lookupModuleInfo[lookupModuleId] && lookupModuleInfo[lookupModuleId].display_field) {
				fieldValue.name = newRecordDetails[lookupModuleInfo[lookupModuleId].display_field.api_name];
			}

			if (subformData && subformData.fromSubform && subformData.recordObj) {
				Lyte.Component.set(subformData.recordObj, currentApiname, fieldValue);
			} else {
				let formDataObj = { cruxFormData: {} };
				formDataObj.cruxFormData[currentApiname] = fieldValue;
				if (layoutCompData.layoutComponentDomNode) {
					layoutCompData.layoutComponentDomNode.setFormData(formDataObj);
				}
			}
		}
	},
	getCurrentLayoutValidFields: function (cxUtilityObj) {
		try {
			return (Object.entries(cxUtilityObj.layoutFieldApiVsMetaObject).map(fieldEntryObj => { return fieldEntryObj[1]; }) || []);
		} catch (err) {
			return [];
		}
	},
	serializeFormDataBeforeSave: function (formData, formFieldList) {
		if (formData && (!formData.$ || !formData.$.model)) {
			for (var fieldKey in formData) {
				let fieldList = formFieldList[fieldKey], fieldValue = formData[fieldKey];
				if (fieldList && fieldList.fieldDataType === "multiselectpicklist" &&
					fieldList.json_type === "jsonarray" && fieldValue && typeof fieldValue === "string") {
					try {
						formData[fieldKey] = JSON.parse(fieldValue);
					} catch (exe) {
						formData[fieldKey] = fieldValue;
					}
				}
			}
		}
		return formData;
	},
	setSaveResponseInMessage: function (detailsObj, layoutCompData) {
		if (detailsObj && detailsObj.saveResponse) {
			let utilityObj = layoutCompData.cxInternalUtilityObj;
			this.setDefautMessageDetails();
			Lyte.objectUtils(utilityObj.commonMessageData, "add", "showCommonMessage", true);//No I18n
			let msg = `Entity Record got ${utilityObj.currentPage === 'edit' ? 'updated' : 'created'} succesfully. Record Id : ${detailsObj.saveResponse.id}`;
			Lyte.objectUtils(utilityObj.commonMessageData, "add", "message", msg);//No I18n
			Lyte.objectUtils(utilityObj.commonMessageData, "add", "messageClassType", 'cxPropMessageTypeSuccess');//No I18n
		}
	},
	setDefautMessageDetails: function () {
		let layoutCompData = this.data.cxPropLayoutComponentData;
		if (!layoutCompData) {
			Lyte.objectUtils(this.data, "add", "cxPropLayoutComponentData", {});//No I18n
		}
		layoutCompData = this.data.cxPropLayoutComponentData;
		if (!layoutCompData.cxInternalUtilityObj) {
			Lyte.objectUtils(layoutCompData, "add", "cxInternalUtilityObj", {});//No I18n
		}
		if (!layoutCompData.cxInternalUtilityObj.commonMessageData) {
			Lyte.objectUtils(layoutCompData.cxInternalUtilityObj, "add", "commonMessageData", {});//No I18n
		}
	},
	getCruxFormDirtyAttributes: function (newRecord = {}, originalRecord = {}) {
		let layoutCompData = this.data.cxPropLayoutComponentData, returnObj = {},
			cxUtilityObj = layoutCompData.cxInternalUtilityObj,
			formFieldList = cxUtilityObj.formFieldList,
			fieldIdVsMetaObject = cxUtilityObj.layoutFieldIdVsMetaObject,
			subformApiNames = Object.keys(cxUtilityObj.subformApinameVsSectionMeta || {}),
			newRecordJson = newRecord.$ && newRecord.$.toJSON() || newRecord,
			currentViewType = cxUtilityObj.currentViewType ? cxUtilityObj.currentViewType : (cxUtilityObj.currentPage === "clone" ? "create" : cxUtilityObj.currentPage);//no i18n
		for (var fieldApiName in newRecordJson) {
			if (formFieldList[fieldApiName]) {
				let fieldMeta = fieldIdVsMetaObject[formFieldList[fieldApiName].fieldId];
				if ((fieldApiName === 'Owner' || fieldApiName === "Layout") && (originalRecord[fieldApiName] && newRecordJson[fieldApiName] && newRecordJson[fieldApiName].id !== originalRecord[fieldApiName].id)) {
					returnObj[fieldApiName] = newRecordJson[fieldApiName];
				} else if (formFieldList[fieldApiName].fieldDataType === "multiuserlookup") {
					returnObj[fieldApiName] = newRecordJson[fieldApiName] && newRecordJson[fieldApiName].length ? newRecordJson[fieldApiName] : [];
				} else if (!$u.isEqual(newRecordJson[fieldApiName], originalRecord[fieldApiName]) && !(subformApiNames && subformApiNames.indexOf(fieldApiName) !== -1)) {
					returnObj[fieldApiName] = newRecordJson[fieldApiName];
				} else if (subformApiNames && subformApiNames.indexOf(fieldApiName) !== -1) {
					returnObj[fieldApiName] = newRecord[fieldApiName];
				} else if (formFieldList[fieldApiName].fieldDataType === "picklist" &&
					newRecordJson[fieldApiName] && originalRecord[fieldApiName] &&
					$u.isEqual(newRecordJson[fieldApiName], originalRecord[fieldApiName]) &&
					formFieldList[fieldApiName].fieldId &&
					(fieldMeta && fieldMeta.default_value && fieldMeta.default_value === newRecordJson[fieldApiName])) {
					returnObj[fieldApiName] = newRecordJson[fieldApiName];
				}
				if (!(fieldMeta && fieldMeta.view_type[currentViewType])) {
					delete returnObj[fieldApiName];
				}
			}
		}
		return returnObj;
	}
});
